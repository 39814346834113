<template>
   <div class="container">
      <el-container class="wrap">
      <!-- 头部 -->
      <el-header >
        <Header />
      </el-header>
      <!-- 中间 -->
      <el-main class="mains">
         <!-- 模考提示 -->
          <div class="main_content mockBox">
            <img :src="userInfo.avatar" alt="" class="avatar" v-if="userInfo.avatar">
            <img src="../assets/image/avatar-87.png" alt="" class="avatar" v-else>
            <div class="rows">
              <div class="font-16 text-bold">昵称</div>
              <el-input v-model="nickname" placeholder="请输入内容"></el-input>
            </div>
            <div class="rows">
              <div class="font-16 text-bold">手机号</div>
              <el-input v-model="userInfo.mobile" placeholder="请输入内容" :disabled="true"></el-input>
            </div>
            <div class="rows">
              <div class="font-16 text-bold">学员号</div>
              <el-input v-model="userInfo.id" placeholder="请输入内容" :disabled="true"></el-input>
            </div>
            <div class="edit" @click="submitInfo()">保存</div>
          </div>
         
      </el-main>
    </el-container>
   </div>
</template>
<script>
export default {
   data() {
      return {
        userInfo: {
          avatar: ''
        },
        nickname: ''
      }
   },
   mounted() {
      this.getUserInfo()
   },
   methods: {
      getUserInfo() {
        this.$api.getUserInfo({}).then(res => {
          if (res.code == 1 && res.data) {
            this.userInfo = res.data.user;
            this.nickname = res.data.user.nickname;
            localStorage.setItem("userInfo",JSON.stringify(res.data))
            this.$store.commit("setUserInfo", res.data);
          }
        })
      },
      submitInfo() {
        if(!this.nickname) {
          this.$message({
            message: '昵称不能为空',
            type: 'warning'
          });
          return;
        }
        this.$api.editUserInfo({
          nickname: this.nickname,
        }).then(res => {
          if(res.code == 1) {
              this.$message({
                message: '已修改',
                type: 'success'
              });
          }else {
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      },
   }
}
</script>
<style  lang="scss" scoped>
.container,.wrap {
  width: 100%;
  height: 100vh;
}
.el-header {
  height: 70px!important;
}

.mains {
  height: calc(100% - 70px);
  padding-top: 40px;
  box-sizing: border-box;
  background: #F6F7FB;
}

.mockBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 2px;
  padding: 100px 0;
  .avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 54px;
  }
  .rows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 309px;
    height: 55px;
    border-radius: 2px;
    border: 1px solid #DDDFE4;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    
  }
  .edit {
    width: 309px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 2px;
    background: $color-title2;
    font-size: 16px;
    color: #fff;
    margin-top: 30px;
    cursor: pointer;
  }
}

::v-deep .el-input {
  width: 70%!important;
}
::v-deep .el-input .el-input__inner {
  border: none!important;
  text-align: right!important;
  padding: 0!important;
}
</style>
